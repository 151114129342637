<template>
  <svg
    class="svg-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_681_89)">
      <path
        d="M0.767928 1.36484V3H0.629793V1.36484H0.767928ZM1.25879 1.36484V3H1.12065V1.36484H1.25879ZM2.02021 2.11729V2.23633H1.21274V2.11729H2.02021ZM2.13364 1.36484V1.48389H1.21274V1.36484H2.13364ZM3.34101 2.49014H3.47914C3.46791 2.6002 3.43759 2.69528 3.38818 2.77539C3.33876 2.85475 3.27138 2.91577 3.18603 2.95845C3.10068 3.00112 2.99773 3.02246 2.87719 3.02246C2.78435 3.02246 2.70012 3.00487 2.6245 2.96968C2.54963 2.93449 2.48525 2.8847 2.43134 2.82031C2.37743 2.75518 2.33588 2.67731 2.30668 2.58672C2.27748 2.49613 2.26288 2.39543 2.26288 2.28462V2.08022C2.26288 1.96942 2.27748 1.86909 2.30668 1.77925C2.33588 1.68866 2.37781 1.61079 2.43246 1.54565C2.48712 1.48052 2.55263 1.43035 2.629 1.39517C2.70536 1.35998 2.79146 1.34238 2.8873 1.34238C3.00335 1.34238 3.10367 1.36372 3.18827 1.4064C3.27288 1.44832 3.33951 1.50897 3.38818 1.58833C3.43759 1.66769 3.46791 1.76353 3.47914 1.87583H3.34101C3.33053 1.78748 3.30732 1.71261 3.27138 1.65122C3.23544 1.58983 3.18565 1.54303 3.12201 1.51084C3.05912 1.4779 2.98088 1.46143 2.8873 1.46143C2.81093 1.46143 2.7428 1.47603 2.6829 1.50522C2.62301 1.53442 2.5721 1.57635 2.53017 1.63101C2.48824 1.68491 2.45605 1.74967 2.43359 1.82529C2.41187 1.90091 2.40102 1.98514 2.40102 2.07798V2.28462C2.40102 2.37446 2.4115 2.45719 2.43246 2.53281C2.45343 2.60843 2.48412 2.67394 2.52455 2.72935C2.56573 2.78475 2.61589 2.8278 2.67504 2.8585C2.73419 2.88919 2.80157 2.90454 2.87719 2.90454C2.97377 2.90454 3.05426 2.88919 3.11864 2.8585C3.18378 2.82705 3.23432 2.78063 3.27026 2.71924C3.30619 2.65785 3.32978 2.58148 3.34101 2.49014Z"
        transform="scale(4)"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_681_89">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.svg-icon {
  height: 16px;
  width: 16px;
  align-items: center;
}
</style>>