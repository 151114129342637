import { api } from "@/api"
import { SmetaTreeStateCache } from '@models/smeta'
import WormNode from '@/assets/model/worm/WormNode'
import project from '../project.module'
import { SmetaOrganizationProject } from '@/assets/model/smeta/SmetaOrganizationProject'
import { AlertService } from "@/assets/app/AlertService"
import { useSmetaConnectionsStore } from "@/pinia/smeta/smetaConnections.store"
import { SmetaPanelType } from "@/components/project/panel/left/smeta/components/utils/SmetaPanelType"

const getDefaultState = () => {
  return {
    projectClassificatorUuid:null,

    projectClassificatorsLoading: false,
    projectClassificators:[],

    nodeTypes: [],
    unitTypes: [],
    units: [],
    dataExportTypes: [],
    ruleTypes: [],

    copiedRule:null,

    lastUnit: {
      unitType: null,
      unitValue: 1
    },

    editClass: null,
    
    nodeTree:[],
    openedNodes:[],

    editRuleItem: null,

    isTreeInPreparation: false,
    
    // items:[],
    accumulator: 0,

    openedCount: 0,

    showOnlyRules: false,
    showOnlyWithSmeta: false,
    showOnlyValidRule: false,

    grandSmeta: null,

    searchText: null,

    checkLogicMode: null,

    expandAfterLoadUuid: null,

    allCodes: [],

    organizationProjects: [],
    showPanelHightLight: false,

    counts: {
      rulesCount: 0,
      validRulesCount: 0,
      withSmetaCount: 0
    },

    scrollToItem: null,
    currentSmetaPanelType: SmetaPanelType.CLASSIFICATOR,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  modules: {
    
  },

  state,

  getters: {
    getProjectClassificator: (state) => uuid => state.projectClassificators.find(e => e.uuid == uuid),
    getTopItemCount: (state) => state.accumulator && (state.items?.length || 0),

    grandSmetaPositionByClassificator: (state) => uuid => {
      let cpt = null
      if (state.grandSmeta) {
        cpt = state.grandSmeta.chapters.find(e => {
          return e.positions.find(p => p.classificatorNodeUuid == uuid)
        })
      }
      let pos = null
      if (cpt) {
        pos = cpt.positions.find(p => p.classificatorNodeUuid == uuid)
      }
      return pos
    },

    getNode: () => uuid => smetaCache.getNode(uuid),
    
    connectionsTreeAllVisibleNodes: ({ openedCount, items, searchText, accumulator }) => {
      accumulator
      const smetaConnectionsStore = useSmetaConnectionsStore()
      let filterParams = {
        hideRules: true
      }
      Object.assign(filterParams, smetaConnectionsStore.filters)
      
      let toshow = items.filter(item => item.nodeType.name != "RULE")
      if (searchText) {
        if (smetaCache.searchText != searchText) {
          if (smetaCache.searchText && searchText.startsWith(smetaCache.searchText)) {
            toshow = smetaCache.founded
          } else {
            toshow = Array.from(smetaCache.tree.map.values())
          }
          smetaCache.searchText = searchText
          let result = [];
          for (let i = 0; i < toshow.length; i++) {
            const el = toshow[i]
            if (el.nodeType.name != 'TOPIC' && el.pressmark && el.pressmark.startsWith(searchText)) {
              result.push(el)
            }
          }
          smetaCache.founded = result
        }
        toshow = smetaCache.founded
      } else {
        if (openedCount == 0) {
          if (filterParams) return toshow.filter(el => applyFilter(el, filterParams))
          else return toshow
        }
      }
      return findVisibleNodes(toshow, filterParams)
    },

    treeAllVisibleNodes: ({ openedCount, items, showOnlyRules, showOnlyWithSmeta, showOnlyValidRule, searchText, accumulator }) => {
      accumulator
      let filterParams = null
      if (showOnlyRules) filterParams ? filterParams.showOnlyRules = showOnlyRules  : filterParams  = {showOnlyRules:showOnlyRules}
      if (showOnlyWithSmeta) filterParams ? filterParams.showOnlyWithSmeta = showOnlyWithSmeta  : filterParams  = {showOnlyWithSmeta:showOnlyWithSmeta}
      if (showOnlyValidRule) filterParams ? filterParams.showOnlyValidRule = showOnlyValidRule  : filterParams  = {showOnlyValidRule:showOnlyValidRule}
      let toshow = items
      if (searchText){

        if (smetaCache.searchText != searchText){
          
          if (smetaCache.searchText && searchText.startsWith(smetaCache.searchText)) {
            toshow = smetaCache.founded
          } else {
            toshow = Array.from(smetaCache.tree.map.values())
          }
          
          smetaCache.searchText = searchText

          let result = [];
          for(let i = 0; i < toshow.length; i++) {
              const el = toshow[i]
              if(el.nodeType.name != 'TOPIC' && el.pressmark && el.pressmark.startsWith(searchText)) {
                  result.push(el)
              }
          }
          
          smetaCache.founded = result
        }
        toshow = smetaCache.founded

      } else {
        if (openedCount == 0) {
          if (filterParams) return toshow.filter(el => applyFilter(el, filterParams))
          else return toshow
        }
      }

      return findVisibleNodes(toshow, filterParams)
    },

    treeAllVisibleNodesForSearch: ({ openedCount, showOnlyRules, showOnlyWithSmeta, showOnlyValidRule }) => (items) => {
      let filterParams = null
      if (showOnlyRules) filterParams ? filterParams.showOnlyRules = showOnlyRules  : filterParams  = {showOnlyRules:showOnlyRules}
      if (showOnlyWithSmeta) filterParams ? filterParams.showOnlyWithSmeta = showOnlyWithSmeta  : filterParams  = {showOnlyWithSmeta:showOnlyWithSmeta}
      if (showOnlyWithSmeta) filterParams ? filterParams.showOnlyValidRule = showOnlyValidRule  : filterParams  = {showOnlyValidRule:showOnlyValidRule}
      if (openedCount == 0) {
        if (filterParams) return items.filter(el => applyFilter(el, filterParams))
        else return  items
      }

      return findVisibleNodes(items, filterParams)
    },

    isOpened: ({ openedCount }) => node => {
      if (openedCount == 0) return false
      else if (openedCount == smetaCache.totalNodes) return true
      return smetaCache.opened.has(node)
    },
    areAllTreeNodesOpened: ({ openedCount }) => {
      if(openedCount == smetaCache.totalNodes && smetaCache.totalNodes == 0) return false
      else if(openedCount !== smetaCache.totalNodes && smetaCache.totalNodes > 0) return false
      return openedCount == smetaCache.totalNodes
    },

    getTreeCache: () => smetaCache,
    hasRules: ({ accumulator }) => (node) => (accumulator, node.hasRules),
  },

  mutations: {
    resetState (state) { 
      const nodeTypes = state.nodeTypes
      const unitTypes  = state.unitTypes
      const dataExportTypes = state.dataExportTypes
      const ruleTypes = state.ruleTypes
      Object.assign(state, getDefaultState())
      state.nodeTypes = nodeTypes
      state.unitTypes = unitTypes
      state.dataExportTypes = dataExportTypes
      state.ruleTypes = ruleTypes
    },
    
    setProjectClassificatorUuid: (state, uuid) => {
      state.projectClassificatorUuid = uuid
    },

    setProjectClassificators: (state, data) => {
      state.projectClassificators = data
    },

    setProjectClassificatorsLoading: (state, flag) => {
      state.projectClassificatorsLoading = flag
    },

    addProjectClassificator: (state, data) => {
      state.projectClassificators.push(data)
    },

    setNodeTypes: (state, items) => {
      state.nodeTypes = items
    },

    setUnitTypes: (state, items) => {
      state.unitTypes = items
    },

    setUnits: (state, items) => {
      state.units = items
    },

    setDataExportTypes: (state, items) => {
      state.dataExportTypes = items
    },

    setRuleTypes: (state, items) => {
      state.ruleTypes = items
    },

    setTreeInPreparation: (state, flag) => {
      state.isTreeInPreparation = flag
    },

    setCopiedRule: (state, rule) => {
      state.copiedRule = rule
    },

    setLastUnit: (state, v) => {
      state.lastUnit = v
    },

    setEditClass: (state, item) => {
      state.editClass = item
    },

    setNodeTree: (state, items) => {
      state.nodeTree = items
    },
    setOpenedNodes: (state, items) => {
      state.openedNodes = items
    },
    
    setItems: (state, items) => { 
      state.accumulator += 1
      state.items = items
      if (state.grandSmeta) smetaCache.bindGrandsmeta(state.grandSmeta)
    },

    setShowOnlyRules: (state, bool) => { 
      state.showOnlyRules = bool
    },

    setShowOnlyWithSmeta: (state, bool) => { 
      state.showOnlyWithSmeta = bool
    },

    setShowOnlyValidRule: (state, bool) => { 
      state.showOnlyValidRule = bool
    },

    updateOpenedCount (state, num) {
      state.openedCount = num
    },

    setEditRuleItem: (state, item) => { 
      state.editRuleItem = null
      state.editRuleItem = item
    },


    setGrandSmeta: (state, item) => { 
      state.grandSmeta = item
      if (state.grandSmeta) smetaCache.bindGrandsmeta(state.grandSmeta)
    },

    setSearchText: (state, text) => { 
      state.searchText = text
    },

    setCheckLogicMode: (state, mode) => {
      state.checkLogicMode = mode
    },

    setExpandAfterLoadUuid: (state, expandAfterLoadUuid) => {
      state.expandAfterLoadUuid = expandAfterLoadUuid
    },

    setShowPanelHightLight: (state, boolean) => {
      state.showPanelHightLight = boolean
    },

    updateCounts: (state, counts) => {
      state.counts = counts
    },

    setScrollTo: (state, scrollToItem) => {
      state.scrollToItem = scrollToItem
    },

    setSmetaPanelType: (state, type) => {
      state.currentSmetaPanelType = type
    },

    setTreeLinks: (state, data) => {
      state.accumulator += 1
      const isWormNodeLink = data.linkTypes.find(type => type == "WormNode")
      const isTaskLink = data.linkTypes.find(type => type == "Task")
      const isCompoudLink = data.linkTypes.find(type => type == "Compound")
      smetaCache.markWithLinks(data.nodeUuid, isWormNodeLink, isTaskLink, isCompoudLink)
    },
  },

  actions: {
    INIT: ({ state, dispatch }) => {
      if (state.nodeTypes.length == 0) dispatch('LOAD_NODETYPES')
      if (state.unitTypes.length == 0) dispatch('LOAD_UNITTYPES')
      if (state.units.length == 0) dispatch('LOAD_UNITS')
      if (state.dataExportTypes.length == 0) dispatch('LOAD_DATAEXPORTTYPES')
      if (state.ruleTypes.length == 0) dispatch('LOAD_RULETYPES')
    },

    LOAD_NODETYPES: ({ state, commit }) => {
      if (state.nodeTypes.length === 0){
        api.smeta.nodetypes().then(data => {
          commit('setNodeTypes', data)
        })  
      }
    },

    LOAD_UNITTYPES: ({ state, commit }) => {
      if (state.unitTypes.length === 0){
        api.smeta.unittypes().then(data => {
          commit('setUnitTypes', data)
        })  
      }
    },

    LOAD_UNITS: ({ commit }) => {
      api.smeta.units().then(data => {
        commit('setUnits', data)
      })  
    },

    LOAD_DATAEXPORTTYPES: ({ state, commit }) => {
      if (state.dataExportTypes.length === 0){
        api.smeta.dataexporttypes().then(data => {
          commit('setDataExportTypes', data)
        })
      }
    },

    LOAD_RULETYPES: ({ state, commit }) => {
      if (state.ruleTypes.length === 0){
        api.smeta.ruletypes().then(data => {
          commit('setRuleTypes', data)
        })  
      }
    },

    LOAD_PROJECT_CLASSIFICATORS: ({ commit }, projectUuid) => {
      commit('setProjectClassificatorsLoading', true)
      return api.smeta.projclasslist(projectUuid).then(data => {
        if (project.state.project.organization || data.some(e => e.organizationUuid != null)) {
          // let indexOther = data.findIndex(el => el.projectUuid != project.state.project.uuid && el.organizationUuid != null)
          let indexOther = data.findIndex(el => el.organizationUuid != null)
          if (indexOther > -1) data.splice(indexOther, 0, {divider:true},{header:project.state.project.organization ? project.state.project.organization.title : 'organization'})
        }
        commit('setProjectClassificators', data)
        commit('setProjectClassificatorsLoading', false)
        return data
      })
    
    },

    LOAD_TREE: ({ commit, dispatch }, uuid) => {
      commit('setTreeInPreparation', true)
      api.smeta.loadFulltreeWithRules(uuid).then((data) => {
        dispatch('commitSmetaTree', data)
        commit('setTreeInPreparation', false)
        dispatch('LOAD_SMETA', uuid)
      }).catch(() => {
        AlertService.error({
          data: {
            error_description: 'Не удалось загрузить классификатор'
          }
        })
        commit('setTreeInPreparation', false)
      })
    },

    LOAD_SMETA: ({ commit }, uuid) => {
      api.smeta.loadGrandSmeta(project.state.project.uuid, uuid).then(data => {
        commit('setGrandSmeta', data)
      })
    },

    getOrganizationProjects: ({ state }, orgUuid) => {
      return api.projects.getListOrganizationProjects(orgUuid).then(data => {
        const classificator = state.projectClassificators.find((item) => item?.uuid == state.projectClassificatorUuid)
        let filtered = data.filter(el => el.uuid != classificator?.projectUuid )
        state.organizationProjects = filtered.map(item => new SmetaOrganizationProject(item))
      })
    },

    updateOrganizationProjects: ({state}) => {
      const classificator = state.projectClassificators.find((item) => item?.uuid == state.projectClassificatorUuid)
      state.organizationProjects.forEach((project) => {
        classificator?.projects?.find((item) => item.projectUuid == project.uuid) ? project.setSelectedValue(true) : project.setSelectedValue(false)
      })
    },
    
    commitSmetaTree:({ state, commit }, rawData) => {
      if (rawData && rawData.length > 0){
        commit('setItems', [])
        commit('updateOpenedCount', 0)
        smetaCache.saveTree(rawData)
        commit('setItems', smetaCache.tree.items)

        if (state.expandAfterLoadUuid != null) {
          smetaCache.expandTo(state.expandAfterLoadUuid)
          let el = smetaCache.getNode(state.expandAfterLoadUuid)
          if (el.isRule) commit('setEditRuleItem', el)
          else if (el.children && el.children.length > 0) commit('setEditRuleItem', el.children[0])
          commit('setExpandAfterLoadUuid', null)
        }

        commit('updateOpenedCount', smetaCache.openedCount)

        commit('updateCounts', { 
          rulesCount: smetaCache.rulesCount, 
          validRulesCount: smetaCache.validRulesCount, 
          withSmetaCount: smetaCache.withSmetaCount 
        })
      } else {
        smetaCache = new SmetaTreeStateCache
        commit('setItems', [])
        commit('updateOpenedCount', 0)
      }
    },

    toggleOpened ({ commit, getters }, node) {
      if (getters.isOpened(node)) {
        smetaCache.opened.delete(node)
        smetaCache.openedCount--
        commit('updateOpenedCount', smetaCache.openedCount)
      }
      else {
        smetaCache.opened.add(node)
        smetaCache.openedCount++
        commit('updateOpenedCount', smetaCache.openedCount)
      }
    },

    expandAllTreeNodes ({ commit }) {
      console.log("expand")
      smetaCache.expandAll()
      commit('updateOpenedCount', smetaCache.totalNodes)
    },
    
    collapseAllTreeNodes ({ commit }) {
      console.log("collapse")
      smetaCache.collapseAll()
      commit('updateOpenedCount', 0)
    },

    expandToRules ({ commit }) {
      smetaCache.expandRules()
      commit('updateOpenedCount', smetaCache.openedCount)
    },

    expandTo ({ commit }, uuid) {
      smetaCache.expandTo(uuid)
      commit('updateOpenedCount', smetaCache.openedCount)
    },



    async updateEditingRule({ state, commit }, rule){
      smetaCache.updateElement(rule)

      if (state.editRuleItem.uuid == rule.uuid) {
        let node = state.editRuleItem
        node.findRule = rule.findRule
        node.notGetMaterials = rule.notGetMaterials
        node.showVolume = rule.showVolume
        node.title = rule.title
        node.titleAttribute = rule.titleAttribute
        node.unitExpression = rule.unitExpression
        node.unitExpressionFormula = rule.unitExpressionFormula
        node.logicValidy = rule.logicValidy
        commit('setEditRuleItem', node)
      }

      let wormNode = WormNode.query().where('classificatorFindRuleUuid', rule.uuid).get()
      for (let wnode of wormNode) {
        WormNode.update({where: wnode.uuid, data: {classificatorFindRule: rule}})
        await WormNode.api().recalculate(wnode.uuid).then(() => console.log('asdjkh'))
      }
      
      commit('updateOpenedCount', smetaCache.openedCount)

      commit('updateCounts', { 
        rulesCount: smetaCache.rulesCount, 
        validRulesCount: smetaCache.validRulesCount, 
        withSmetaCount: smetaCache.withSmetaCount 
      })
    },



    addParentElement ({ commit }, data) {
      let newElem = smetaCache.addParentElement(data)
      commit('setItems', smetaCache.tree.items)
      commit('updateOpenedCount', smetaCache.openedCount)
      return newElem
    },

    addChildElement ({ commit }, data) {
      let newElem = smetaCache.addChildElement(data)
      commit('updateOpenedCount', smetaCache.openedCount)
      commit('updateCounts', { 
        rulesCount: smetaCache.rulesCount, 
        validRulesCount: smetaCache.validRulesCount, 
        withSmetaCount: smetaCache.withSmetaCount 
      })
      commit('setItems', smetaCache.tree.items)
      return newElem
    },

    updateElement ({ commit }, data) {
      let elem = smetaCache.updateElement(data)
      

      let wormNode = WormNode.query().where((item) => item.classificatorNode!=null && item.classificatorNode.uuid == elem.uuid).get()

      for (let wnode of wormNode) {
        // WormNode.update({where: wnode.uuid, data: {classificatorNode: elem}})
        WormNode.api().recalculate(wnode.uuid)
      }

      commit('updateOpenedCount', smetaCache.openedCount)
      commit('updateCounts', { 
        rulesCount: smetaCache.rulesCount, 
        validRulesCount: smetaCache.validRulesCount, 
        withSmetaCount: smetaCache.withSmetaCount 
      })
      return elem
    },

    removeElementByUuid ({ state, commit }, uuid) {
      smetaCache.removeElementByUuid(uuid, state.editRuleItem)
      commit('setItems', smetaCache.tree.items)
      commit('updateOpenedCount', smetaCache.openedCount)
      commit('updateCounts', { 
        rulesCount: smetaCache.rulesCount, 
        validRulesCount: smetaCache.validRulesCount, 
        withSmetaCount: smetaCache.withSmetaCount 
      })
    },

    removeElementByUuidDeep ({ commit }, uuid) {
      smetaCache.removeElementByUuidDeep(uuid)
      commit('setItems', smetaCache.tree.items)
      commit('updateOpenedCount', smetaCache.openedCount)
      commit('updateCounts', { 
        rulesCount: smetaCache.rulesCount, 
        validRulesCount: smetaCache.validRulesCount, 
        withSmetaCount: smetaCache.withSmetaCount 
      })
    },

    moveElement ({ commit }, data) {
      smetaCache.moveElement(data)
      commit('setItems', smetaCache.tree.items)
      commit('updateOpenedCount', smetaCache.openedCount)

      commit('updateCounts', { 
        rulesCount: smetaCache.rulesCount, 
        validRulesCount: smetaCache.validRulesCount, 
        withSmetaCount: smetaCache.withSmetaCount 
      })
    },
    
    findVisibleNodesAction({showOnlyRules, showOnlyWithSmeta, showOnlyValidRule},items){
      let filterParams = null
      if (showOnlyRules) filterParams ? filterParams.showOnlyRules = showOnlyRules  : filterParams  = {showOnlyRules:showOnlyRules}
      if (showOnlyWithSmeta) filterParams ? filterParams.showOnlyWithSmeta = showOnlyWithSmeta  : filterParams  = {showOnlyWithSmeta:showOnlyWithSmeta}
      if (showOnlyWithSmeta) filterParams ? filterParams.showOnlyValidRule = showOnlyValidRule  : filterParams  = {showOnlyValidRule:showOnlyValidRule}
      return findVisibleNodes(items, filterParams)
    }
  }
}

let smetaCache = new SmetaTreeStateCache
function findVisibleNodes (tree, filterParams) {
  let array = []

  let preFiltred = tree.sort((a, b) => {
    if (a.pressmark < b.pressmark) return -1
    else return 1
  })
  if (filterParams) preFiltred = tree.filter(i => applyFilter(i,filterParams))

  for (const node of preFiltred) {
    array.push(node)
    if (smetaCache.opened.has(node)) {
      if (node.isPenult) {
        let filtrecChilds = node.children
        filtrecChilds = filtrecChilds.sort((a, b) => {
          if (a.pressmark < b.pressmark) return -1
          else return 1
        })
        if (filterParams) filtrecChilds = node.children.filter(i => applyFilter(i,filterParams))
        array.push(...filtrecChilds)
      } else array.push(...findVisibleNodes(node.children, filterParams))
    }
  }
  return array
}

function applyFilter(item, params) {
  if (item.isRule && params.hideRules) return false
  if (item.isRule) {
    if (params.showOnlyValidRule) return item.logicValidy && item.logicValidy.valid
    else return true
  }
  // else if (params.showOnlyRules && params.showOnlyWithSmeta) return item.hasGrandSmeta || item.hasRules
  if (params.showOnlyRules && params.showOnlyWithSmeta && params.showOnlyValidRule) return item.hasGrandSmetaAndRules && item.hasValidRule
  if (params.showOnlyRules && params.showOnlyWithSmeta) return item.hasGrandSmetaAndRules
  if (params.showOnlyRules && params.showOnlyValidRule) return item.hasValidRule && item.hasRules
  if (params.showOnlyValidRule && params.showOnlyWithSmeta) return item.hasValidRule && item.hasGrandSmetaAndRules
  if (params.showOnlyRules) return item.hasRules
  if (params.showOnlyWithSmeta) return item.hasGrandSmeta
  if (params.showOnlyValidRule) return item.hasValidRule
  if (params.onlyLinks) return item.hasAnyLink

  if (params.withClassificator) return item.hasCompundLink
  if (params.withWorm) return item.hasWormNodeLink
  if (params.withTask) return item.hasTaskLink
  else return true
}

export function getSmetaCacheItem(uuid) { 
  return smetaCache.tree.map.get(uuid)
}