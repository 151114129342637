import xorBy from "lodash/xorBy"

import { api } from '@/api'
import { AxisUtils } from '@models/axis'
import { projectService } from "@/_services"

import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import CollisionHighlightService from '@/components/project/panel/left/components/collision/CollisionHighlightService.js'

import i18n from "@/plugins/i18n";

const sort = { field: 'createDate', direction: 'asc' }

const FilterType = Object.freeze({ ALL: 0, AXIS_NODES: 1, GROUP: 2, ELEMENTS: 3, RULES: 4 })

const geometryAttr = {
  TOTAL_SURFACE_AREA: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c201", notCreate: false },
  TOTAL_SHAPE_VOLUME: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c202", notCreate: false },
  SURFACE_AREA_ALONG_X: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c204", notCreate: false },
  SURFACE_AREA_ALONG_Y: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c205", notCreate: false },
  SURFACE_AREA_ALONG_Z: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c206", notCreate: false },
  BOUNDING_BOX_SIZE_ALONG_X: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c207", notCreate: false },
  BOUNDING_BOX_SIZE_ALONG_Y: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c208", notCreate: false },
  BOUNDING_BOX_SIZE_ALONG_Z: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c209", notCreate: false },
  LARGEST_FACE_DIRECTION: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c203", notCreate: true },
  LARGEST_FACE_AREA: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c210", notCreate: true }
}

function checkChildren(tree, uuids) {
  if (tree != null && tree.length > 0) {
    let countChildren = tree.length
    let countActive = 0
    tree.forEach(node => {
      if (uuids.has(node.uuid))
        countActive++
    })

    return countChildren === countActive
  }
  else return false
}

function fillClassPathObj(nodes, uuids) {
  if (nodes.length > 0) {
    let classPaths = []

    nodes.forEach(node => {
      let paths = []
      if (node && uuids.has(node.uuid) || node.globalId === "S") {
        let path = {}
        path.uuid = node.globalId === "S" ? "synthetic" : node.uuid
        path.allChildrenSelect = checkChildren(node.children, uuids)
        paths.push(path)
        
        let childrenPaths = fillClassPathObj(node.children, uuids)
        if (childrenPaths) paths = [...paths, ...childrenPaths]
      } 
      
      if (paths && paths.length > 0)
        classPaths.push(...paths)
    })

    return classPaths
  }
  else return null
}

function reformateClassPath(classPath, classPathObj) {
  let newClassPath = []
  classPath.forEach(path => {
    newClassPath.push(classPathObj.find(pathObj => {
      if (pathObj.uuid === path || (pathObj.uuid === "synthetic" && path.includes("synthetic")))
        return pathObj
    })) 
  })

  return newClassPath
}

let emptyFilter = {
  axisNodes: null,
  elements: null,
  rules: null
}

let status = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
}

const getDefaultState = () => {
  return {
    collisions: [],
    statusCollision: status.INITIAL,
    collisionsTask: [],

    filter: emptyFilter,
    filterType: FilterType.ALL,

    collisionSelectedView: null,
    collisionSelectedGeometry: null,
    collisionSelectedElements: [],
    collisionSelectedElementsModel: [],
    collisionSelectedInfo: [],

    collisionSelectedGlobalIds: {
      globalIdA: null,
      globalIdB: null,

      choosen: null,
      element: null,
      collisionUuid: null
    },

    tabCollision: true,

    collisionTypes: [],

    selectedCollision: null, //TODO: проверить надобность / дублируется в collision-table

    intersectionModel: null,

    loadElement: {
      isNeedWait: true,
      status: false,
    }

  }
}
const state = getDefaultState()


const getters = {
  preparedCollisions: ({ collisions }) => collisions,
  preparedCollisionsTask: ({ collisionsTask }) => collisionsTask,
  isLoadingCollision: ({ statusCollision }) => statusCollision == status.LOADING,

  choosenId: ({ collisionSelectedGlobalIds }) => collisionSelectedGlobalIds.choosen,

  selectedAxisNodes: ({ filter }) => filter.axisNodes || [],
  selectedElements: ({ filter }) => filter.elements || [],

  assembledFilter: ({ filter }, getters, rootState, rootGetters) => {
    const project = rootGetters['project/projectUuid']
    let res = { project, sort }

    if (filter.elements) res.elementGuids = filter.elements.map(i => i.globalId)
    if (filter.axisNodes) {
      res.axisId = rootGetters['axis/selectedAxis'].value
      res.elementClassPath = filter.axisNodes.map(i => i.stack)
      let classPathObj = {}
      if (rootGetters['axis/tree/totalEndpoints'] === res.elementClassPath.length) {
        classPathObj.massSelect = true
      }
      else {
        let classPaths = new Set()
        res.elementClassPath.forEach(paths => {
          paths.forEach(path => {
            classPaths.add(path)
          })
        })
        
        let items = rootState.axis.tree.items
        let newPaths = fillClassPathObj(items, classPaths)

        let newClassPath = []
        res.elementClassPath.forEach(path => {
          newClassPath.push(reformateClassPath(path, newPaths))
        })

        classPathObj.paths = newClassPath
      }
      res.elementClassPathObj = classPathObj

      if (res.axisId === 5) {
        res.axisId = rootState.axis.selectedAxisGroup.value
      }
    }
    return res
  },

  collisionSelectedElement: ({ collisionSelectedGlobalIds }) => collisionSelectedGlobalIds.element
}

const actions = {
  reset({ dispatch, commit }) {
    commit('RESET_WITH_FILTER', {})
    dispatch('loadCollisions')
  },

  resetInDestroy({ dispatch, commit }) {
    commit('RESET_WITH_FILTER', {})
    dispatch('clearCollisions')
  },

  selectAxisNodes({ commit, dispatch, getters, rootState, rootGetters }, { node, send = true }) {
    let arraylist = Array.isArray(node) ? AxisUtils.pathNodesList(node) : AxisUtils.pathNodesList([node])
    let lastArrayList = []
    arraylist.forEach(array => {
      let lastNode = array[array.length - 1];
      lastNode.stack = array.map(item => {
        if (item.globalId === "S")
          return "synthetic_" + item.uuid
        return item.uuid
      });
      lastArrayList.push(lastNode)
    })
    if (xorBy(getters.selectedAxisNodes, lastArrayList, 'extraId').length > 0) {
      let axisNodes = xorBy(getters.selectedAxisNodes, lastArrayList, 'extraId')
      commit('RESET_WITH_FILTER', { axisNodes })

      dispatch('loadCollisions').then(() => {
        if (send) {
          let projectUUID = rootGetters['project/projectUuid']
          let axis = rootGetters['axis/selectedAxis']
          let list = axisNodes.map(el => el.extraId)

          api.collisionSettings.selectAxisNodes(projectUUID, axis.value, list)
        }
      })
    } else {
      if (lastArrayList.length == 0 && send) {
        api.collisionSettings.selectAxisNodes(rootGetters['project/projectUuid'], rootGetters['axis/selectedAxis'].value, [])
      }
      commit('RESET_WITH_FILTER', null)
      if (!rootState.collision.table.taskCollision.isActive) {
        commit('collision/table/SET_TABLE_ITEMS', [], { root: true })
      }
      commit('collision/table/SET_TABLE_ITEMS_BUFFER', [], { root: true })
    }
  },

  selectElements({ dispatch, commit }, elements) {
    commit('RESET_WITH_FILTER', { elements })
    dispatch('loadCollisions')
  },

  clearCollisions({ commit }) {
    commit('SET_COLLISIONS', [])
    commit('SET_LOAD_COLLISION', status.LOADED)
  },

  loadCollisions({ commit, getters, dispatch }) {
    commit('SET_LOAD_COLLISION', status.LOADING)

    dispatch('getCollisionTypes')

    return api.collisions.loadCollisionsTableGroups(getters.assembledFilter).then((data) => {
      dispatch('collision/table/initTableItems', data, { root: true })
      commit('SET_COLLISIONS', data)
      commit('SET_LOAD_COLLISION', status.LOADED)
      return
    })
  },

  setCollisionFromTask({ commit }, collisions) {
    commit('SET_COLLISIONS_TASK', collisions)
  },

  setCollisionUuid: ({ state }, id) => {
    state.collisionSelectedGlobalIds.collisionUuid = id
  },

  setChoosenGlobalId: ({ state, rootGetters }, id) => {
    const projectUuid = rootGetters['project/projectUuid']
    state.collisionSelectedGlobalIds.choosen = id

    if (!id || state.collisionSelectedGlobalIds.element?.globalId == id) {
      state.collisionSelectedGlobalIds.choosen = null
      state.collisionSelectedGlobalIds.element = null
      return
    }
    if (id[0] != "_" && id[1] != "_") {
      state.loadElement.status = true
      projectService.loadElementByGlobal(id, projectUuid).then(element => {
        state.collisionSelectedGlobalIds.element = buildElement(element)
        state.loadElement.status = false
      })
    }
  },

  setChoosenElementGlobalId: ({ state }, choosenElement) => {
    if (!choosenElement) {
      state.collisionSelectedGlobalIds.element = null
      return
    }
    if (choosenElement.globalId !== null) {
      state.collisionSelectedGlobalIds.choosen = choosenElement.globalId
      
      if (choosenElement.globalId[0] != "_" && choosenElement.globalId[1] != "_") {
        state.collisionSelectedGlobalIds.element = buildElement(choosenElement)
      }
    }
  },

  setChoosenElement: ({ state }, element) => {
    if (!element) {
      state.collisionSelectedGlobalIds.element = null
      return
    }

    state.collisionSelectedGlobalIds.element = buildElement(element)
  },

  deselectCollision: ({ commit, dispatch, state }, restoreModel = true ) => {
    commit('setCollisionSelectedElements', [])
    commit('setCollisionSelectedElementsModel', [])
    commit('setCollisionSelectedInfo', [])

    if (state.intersectionModel) {
      CollisionHighlightService.destroyIntersectionModel(state.intersectionModel)
    }
    commit('setIntersectionModel', null)

    commit('setCollisionSelectedGlobalIds', { globalIdA: null, globalIdB: null })

    dispatch('setChoosenGlobalId', null)
    dispatch('setCollisionUuid', null)

    commit('setCollisionSelectedGeometry', null)
    commit('setCollisionSelectedView', null)

    if (restoreModel) XeokitMediator.Models.restoreModels()
  },

  loadGeometryCollisionIntersect: ({ commit }, id) => {
    return api.collisions.getCollisionGeometry(id).then(data => {
      commit('setCollisionSelectedGeometry', data)
      return data
    })
  },

  loadCollisionSelectedView: ({ commit }, id) => {
    return api.collisions.getCollisionView(id).then(data => {
      commit('setCollisionSelectedView', data)
      return data
    })
  },

  loadCollisionGroupView: ({ commit }, id) => {
    return api.collisionGroups.getGroupCollisionView(id).then(data => {
      commit('setCollisionSelectedView', data)
      return data
    })
  },


  getCollisionTypes({ state, commit }) {
    if (state.collisionTypes.length === 0){
      return api.collisions.getCollisionTypes().then(types => {
        commit('setCollisionTypes', types)
        return types
      })
    }
  },

  exportToReport({ state, getters, rootState, rootGetters }, params) {
    if (rootState.collision.table.taskCollision.isActive) {
      let list = state.collisionsTask.map(el => el.uuid)
      params.collisionsUuid = list
      params.taskCollision = true
    } else {
      params.filter = getters.assembledFilter
    }
    api.collisions.initCollisionsReportProcess(rootGetters['project/projectUuid'], params)
  },
}

const mutations = {
  resetState(state) { 
    const collisionTypes = state.collisionTypes
    Object.assign(state, getDefaultState()) 
    state.collisionTypes = collisionTypes
  },

  RESET_WITH_FILTER: (state, filter) => {
    state.filter = { ...emptyFilter, ...filter }
    state.collisions = []
  },

  UPDATE_WITH_FILTER: (state, filter) => {
    state.filter = { ...state.filter, ...filter }
  },

  SET_COLLISIONS: (state, data) => {
    if (data && (data.length > 1 || data[0]?.collisions)) {
      let collisionList = []
      data.forEach(group => group.collisions.forEach(collision => collisionList.push(collision)))

      state.collisions = collisionList
    }
    else {
      state.collision = []
    }
  },

  SET_COLLISIONS_FOR_REPLACE: (state, data) => {
    state.collision = [...data]
  },

  SET_LOAD_COLLISION: (state, status) => {
    state.statusCollision = status
  },

  SET_COLLISIONS_TASK: (state, data) => {
    if (!data || data.length === 0) {
      state.collisionsTask = []
    }
    else {
      let list = Array.isArray(data) ? data : [data]
      state.collisionsTask = list.map((el, index) => {
        el = collisionMapper(el)
        el.index = index
        el.selected = false
        el.hidden = false
        return el
      })
    }
  },

  setTabCollision: (state, isCollision) => {
    state.tabCollision = isCollision
  },

  // TODO: Избавиться от метода (временный)
  setCollisionSelectedElements: (state, els) => {
    state.collisionSelectedElements = els
  },
  setCollisionSelectedElementsModel: (state, els) => {
    state.collisionSelectedElementsModel = els
  },
  setCollisionSelectedInfo: (state, els) => {
    state.collisionSelectedInfo = els
  },
  setCollisionSelectedGlobalIds: (state, ids) => {
    state.collisionSelectedGlobalIds.globalIdA = ids.globalIdA
    state.collisionSelectedGlobalIds.globalIdB = ids.globalIdB
  },

  setCollisionSelectedView: (state, view) => {
    state.collisionSelectedView = view
  },

  setCollisionSelectedGeometry: (state, geom) => {
    state.collisionSelectedGeometry = geom
  },

  setCollisionTypes: (state, types) => {
    state.collisionTypes = types
  },

  setSelectedCollision(state, collision) {
    state.selectedCollision = collision
  },

  setIntersectionModel: (state, model) => {
    state.intersectionModel = model
  },
}

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions
}

// function downloadReport(data, type) {
//   let a = document.createElement("a")
//   a.href = URL.createObjectURL(new Blob([data]))
//   a.download = `table.${type}`
//   a.click()
// }

function collisionMapper(c) {
  let ext = { ...c }
  let { info, status, elementA, elementB, note, createDate, collisionResponder, groupName, tasks, collisionValue } = c

  ext.displayedStatus = status.title || ''
  ext.displayedNote = note
  ext.displayedDate = createDate
  ext.displayedResponder = collisionResponder?.modelTitle

  ext.displayedFloorA = (elementA && elementA.uuid != null) ? elementA.floorName : ""
  ext.displayedFloorB = (elementB && elementB.uuid != null) ? elementB.floorName : ""

  let row = info || {}
  let distanceList = collisionValue && collisionValue.values.length > 0 ? collisionValue.values : []
  distanceList = distanceList.map(el => el += " мм")

  ext.displayedName = row.finder
  ext.displayedDescription = row.shortDescription != "" ? row.shortDescription : row.description
  ext.tooltipDescription = row.description

  let rowA = row.modelA?.includes('model.name') ? i18n.t('notify.' + row.modelA.replace("{", "").replace("}", "")) : row.modelA
  let rowB = row.modelB?.includes('model.name') ? i18n.t('notify.' + row.modelB.replace("{", "").replace("}", "")) : row.modelB
  ext.displayedSection = (elementB && elementB.uuid != null) ? `${rowA} - ${rowB}` : `${rowA}`

  ext.displayedModelA = (elementA && elementA.uuid != null) ? elementA.name : ""
  ext.displayedModelB = (elementB && elementB.uuid != null) ? elementB.name : ""
  // TODO Иногда "перевернутые" данные
  // ext.displayedModelA = row.elementATitle
  // ext.displayedModelB = row.elementBTitle

  ext.displayedSample = (elementB && elementB.uuid != null) ? `${row.ruleATitle} / ${row.ruleBTitle}` : `${row.ruleATitle}`

  ext.displayedDistance = row.value

  ext.displayedGroup = groupName
  ext.displayedAxis = '--'
  ext.displayedTask = tasks

  ext.distanceList = distanceList

  return ext
}

function buildElement(element) {
  if (element) {
    if (element.geometryInfo && element.geometryInfo.additionalData) {
      let json_data = JSON.parse(element.geometryInfo.additionalData)
      element.geometry = Object.keys(json_data).map(key => ({
        "name": key,
        "operand": "GEOMETRY",
        "uuid" : geometryAttr[key]?.uuid,
        "topLevel": true,
        "notCreateGroup": geometryAttr[key]?.notCreate,
        "doubleValue": json_data[key],
        "stringValue": "" + json_data[key],
        "unit": key.indexOf("_AREA") > 0 ? "{unit.shortName.square_metre}" : key.indexOf("_SIZE") > 0 ? "{unit.shortName.metre}" : key.indexOf("_VOLUME") > 0 ? "{unit.shortName.cubic_metre}" : ""
      }))
    }
    element.details = element.children.sort((a, b) => (a.children.length > 0 ? 1 : 0) - (b.children.length > 0 ? 1 : 0) || a.name.localeCompare(b.name))
    element.details.forEach(el => { if (el.children.length > 0) el.children.sort((a, b) => a.name.trim().localeCompare(b.name.trim())) })
  }

  return element
}