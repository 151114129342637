import { openDB } from 'idb'

const SCHEMA_VERSION = 1
const DB_NAME = 'BIMIT'

const storeNames = ['floatingPanels']

function open() {
  const dbOptions = Object.assign({
    upgrade(db) {
      for (const store of storeNames) {
        if (!db.objectStoreNames.contains(store)) {
           db.createObjectStore(store)
        }
      }
    }
  })

  return openDB(DB_NAME, SCHEMA_VERSION, dbOptions)
}

async function deleteDb() {
  const db = await open()
  const arr = []
  for (const store of storeNames) {
    arr.push(db.clear(store))
  }
  await Promise.all(arr)
}

async function getById(storeName, key) {
  const db = await open()
  return db.get(storeName, key)
}

async function getAll(storeName) {
  const db = await open()
  return db.getAll(storeName)
}

async function getOrCreate(storeName, key, defaultData) {
  const db = await open()
  const data = await db.get(storeName, key)
  if (data) return data

  return db.put(storeName, defaultData, key).then(()=> {
    return db.get(storeName, key)
  })
}

async function put(storeName, object, key) {
  const db = await open()
  return db.put(storeName, object, key)
}

async function putAll(storeName, objects, key) {
  const db = await open()
  const tx = db.transaction(storeName, 'readwrite')
  const actions = []
  for (const obj of objects) {
    actions.push(key ? tx.store.put(obj, obj[key]) : tx.store.put(obj, obj.uuid))
  }
  actions.push(tx.done)
  return Promise.all(actions)
}

async function deleteAll(storeName) {
  const db = await open()
  const tx = db.transaction(storeName, 'readwrite')
  await Promise.all([tx.store.clear(), tx.done])
}

async function deleteById(storeName, key) {
  const db = await open()
  await db.delete(storeName, key)
}

async function deleteAllById(storeName, keys) {
  const db = await open()
  const tx = db.transaction(storeName, 'readwrite')
  const actions = []
  for (const key of keys) {
    actions.push(tx.store.delete(key))
  }
  actions.push(tx.done)
  return Promise.all(actions);
}


export const useDB = () => ({
  deleteDb,
  getById,
  getAll,
  getOrCreate,
  put,
  putAll,
  deleteAll,
  deleteById,
  deleteAllById,
})