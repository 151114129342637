import { $_app_server } from '@/_helpers'
import { App } from '@app/App'
import { ApiPresets } from '@/utils'
import { AlertService } from '@app/AlertService'
import { subscribePush, unsubscribePush } from '@/plugins/webPush/webPush'
import { useDB } from '@/api/idb/db'
//import  router  from '@/router'

// import axios from "axios"

export const authUserService = {
  login,
  social,
  logout,

  restore,
  changePassword,
  changeTariff,
  confirmRegistration,

  listGrantTypes,
  listTariffList,

  refreshToken
}

function social(source, social_type) {
  localStorage.setItem("bimit_social_type", social_type)
  return auth('oauth/social/' + social_type, source, { }, social_type)
}

function login(username, password) {
  localStorage.removeItem("bimit_social_type")
  localStorage.removeItem("access_token")
  
  let security = new FormData();
  security.append("grant_type", 'password')
  security.append("username", username.trim().toLowerCase())
  security.append("password", password.trim())

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  
  return auth('oauth/token', security, headers)
}

function auth(url, security, headers, login_type) {
  // return $_app_server_noAuth.post(url, params, headers)
  return $_app_server.post(url, security, { '_guest': true, headers })
    .then(({ data }) => {
      data.login_type = login_type
      
      App.auth.use(data)
      $_app_server.defaults.headers.common.Authorization = 'Bearer ' + App.auth.accessToken
      subscribePush()
      return true
    })
    .catch(error => {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          logout()
          
          App.auth.use({access_token: null})
          location.reload()
          return false
        }
      }
    })
}

function logout(info, error) {
  unsubscribePush()
  useDB().deleteDb()
  localStorage.removeItem("bimit_social_type")
  $_app_server.defaults.headers.common.Authorization = null

  App.auth.clear()

  localStorage.setItem("locationHref", location.href)
  
  if (info) {
    AlertService.info({info:  info })
  }
  if (error) {
    AlertService.error({info:  error })
  }
  //location.href="/"

//  router.push('/home').catch(()=>{})
  window.location = App.social.authserver+"/logout?logout_uri="+App.social.logout;
  //router.push('/login').catch(()=>{})
}



function restore (username) {
  return $_app_server.post('oauth/restore', {login:username.trim().toLowerCase()}, ApiPresets.GUEST).then(response => response.data)
}

function confirmRegistration (obj) {
  return $_app_server.post('oauth/confirm', obj, ApiPresets.GUEST).then(response => response.data)
}

function changePassword (profileUuid, obj) {
  return $_app_server.put(`profiles/${profileUuid}/change/password`, obj).then(response => response.data)  
}

function changeTariff (profileUuid, tariff) {
  return $_app_server.patch(`profiles/${profileUuid}/tariff/${tariff}`).then(response => response.data)  
}


function listGrantTypes () {
  return $_app_server.get('oauth/granttypes').then(response => response.data)
}
function listTariffList () {
  return $_app_server.get('tarifflist').then(response => response.data)
}


function refreshToken () {
  let security = new FormData();
  security.append("grant_type", 'refresh_token')
  security.append("refresh_token", App.auth.refreshToken)

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Authorization': '',
  }

  let soc_type = localStorage.getItem('bimit_login_type') ? "/" + localStorage.getItem('bimit_login_type') : ""

  return $_app_server.post('oauth/token'+soc_type, security, { '_guest': true, headers })
  .then(response => {
    App.auth.use(response.data)
  })
}

